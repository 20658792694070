
import { computed, defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Registration Completed",
  props: {
    userName: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n({ useScope: "global" });
    const state = reactive({
      userName: props.userName,
    });

    const display = computed(() => t("Your email is not verified").split("\n"));
    console.log(display);

    return { state, display };
  },
});
