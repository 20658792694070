import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main__section registration__completed" }
const _hoisted_2 = { class: "box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("section", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("p", null, _toDisplayString(_ctx.userName), 1),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.display, (text, index) => {
        return (_openBlock(), _createBlock("div", { key: index }, _toDisplayString(text), 1))
      }), 128))
    ])
  ]))
}